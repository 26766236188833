import React from "react";
import { Link } from "gatsby";

const TermsAndConditions = () => (
  <section>
    <div className="container py-16">
      {/* <h1 className="font-bold text-6xl mb-[100px]">PRIVACY POLICY</h1> */}
      <h2 className="font-bold mb-3 text-[30px]">{"TERMS & CONDITIONS"}</h2>
      <p className="mb-6">
        This website/media (“Media”) is operated by Digital Research Corporation (“DRC”). Please
        read the following terms and conditions of use (“Terms”) before using DRC’s Media and only
        use it if you agree with it. DRC may alter, add or delete URLs (contents, information, data,
        text, images, video, audio etc.) on DRC’s Media, suspend or cancel operation of Media
        without prior notice.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">COPYRIGHT</h2>
      <p className="mb-6">
        Of the contents which consist and appear on Media, the copyrights for the contents cited
        from external websites belong to the companies, organizations, or individuals of the
        sources. All the other contents are copyrighted by DRC and may not be used, including
        copying, modifying, uploading, posting, transmitting, distributing, licensing, selling,
        publishing, translating, reproducing, commercializing, etc. without written permission by
        DRC.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">THIRD PARTY LINKS</h2>
      <p className="mb-6">
        DRC’s Media may contain links to other websites operated by third parties and DRC may allow
        such third parties to contain links to any other third party websites. DRC may provide these
        links only as a convenience and does not guarantee anything of any third party companies’
        websites or products.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">PRIVACY POLICY</h2>
      <p className="mb-6">
        Please refer to <Link to="/privacy-policy">DRC’s Privacy Policy page</Link>.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">RESTITUTION FOR DAMAGES</h2>
      <p className="mb-6">
        If you violate the laws and regulations or these Terms and cause damage to DRC, DRC shall be
        able to make a claim of restitution for damages to you.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">APPLICABLE LAW</h2>
      <p className="mb-6">
        By using DRC’s Media, you agree that these Terms will be interpreted and applied by Japanese
        law, regardless of the conflict of law rules. By using DRC’s Media, you agree that all
        disputes related to or derived from these Terms will be subject to the jurisdiction of the
        Tokyo District Court or the Tokyo Summary Court, and you agree that any claim brought by you
        pursuant to these Terms will be brought solely in those courts and no other court.
      </p>
      <h2 className="font-bold mb-3 text-[30px]">REVISION OF TERMS AND CONDITIONS</h2>
      <p className="mb-6">DRC may change these Terms of our Media by posting revised Terms.</p>
    </div>
  </section>
);

export default TermsAndConditions;
